import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-theme"
export default class extends Controller {
  static values = {
    userTheme: String,
  };

  connect() {
    this.setTheme();
  }

  setTheme() {
    const userTheme = this.userThemeValue;
    if (userTheme === "dark") {
      this.applyTheme("dark");
    } else if (userTheme === "light") {
      this.applyTheme("light");
    } else {
      // 'auto' or undefined
      this.applyAutoTheme();
    }
  }

  applyTheme(theme) {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
      document.documentElement.classList.remove("auto");
    } else if (theme === "light") {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
      document.documentElement.classList.remove("auto");
    }
  }

  applyAutoTheme() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      this.applyTheme("dark");
    } else {
      this.applyTheme("light");
    }
  }

  setLightTheme() {
    localStorage.theme = "light";
    this.applyTheme("light");
  }

  setDarkTheme() {
    localStorage.theme = "dark";
    this.applyTheme("dark");
  }

  toggleTheme() {
    if (document.body.classList.contains("dark")) {
      this.setLightTheme();
    } else {
      this.setDarkTheme();
    }
  }
}
